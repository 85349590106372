import React, { Component } from "react";
import styles from './hero.module.scss';
import Reveal from 'react-reveal/Reveal';

import { Parallax, Background } from 'react-parallax';



class Hero extends Component {
 

  get_image(type,tile_style){
      console.log("TYPE");
      console.log(type)
    let image;

      if(type=="video"){
        image =<div className={styles.video}  className={styles.animation_test}>
        <video muted autoPlay playsinline="true" loop="true">
            <source src={this.props.video_url} type="video/mp4"></source>
        </video>
        </div>

      } else {
        image = 

        <div style={tile_style}  className={styles.animation_test}></div>

      }
      return image;
  }
 render() {
   
  let background_image = this.props.img;
  let parent_style ={
    "height": 0,
    "padding-bottom": "56.25%",
    "position": "relative"

   
  }

  let tile_style = {
    "position":"absolute",
    "background-image":"url("+ background_image +")",
    "height":"100%",
    "width":"100%",
   
    "background-size":"100% auto",
    "background-repeat":"no-repeat",
    
  }

  let overlay_opacity = {
    opacity:this.props.overlay_opacity,
    "background-color": "black"
  }
 



  return (
 

<div style={parent_style} className={styles.hero}>

    {this.get_image(this.props.hero_type,tile_style)}


    

   
    

    <div className={styles.overlay} style={overlay_opacity}></div>
    <div className={styles.copy}>
        <Reveal effect="fadeInUp">
             <h1 className={styles.project_title}><span>
         {this.props.project_name}</span></h1>
        </Reveal>


    </div>
  
   
</div>



  )
 }
}

export default Hero ;