import React, { Component } from "react";
import styles from './ThreeColumnsDesktop.module.scss';
import Reveal from 'react-reveal/Reveal';
import { Parallax, Background } from 'react-parallax';


class DesktopColumn extends Component {

 getImages(images){
    let content = [];
    for(var i=0;i<images.length;i++){

        content.push(
        
            <Reveal effect="fadeInUp" duration={2000}>
        <div className={styles.image}> <img alt="cover" src={images[i].image.url} /></div>
        </Reveal>
        );
    }
    return content;
 }
 render() {
   

    console.log(this.props.images)

  return (


   

          
                     this.getImages(this.props.images)
          

  )
 }
}

export default DesktopColumn;
