import React, { Component } from 'react';
import styles from './Menu.module.scss';
import { BrowserRouter as Router,Link} from 'react-router-dom'



class MenuItem extends Component {
  constructor(props){
    super(props);
    this.state = {
      hamburger_menu_visibility : 'hidden'



    }




  }

  getItem(state){

    let menu_style ={
      color:this.props.color,
      "background-image": `linear-gradient(to right, ${this.props.color} 0, ${this.props.color} 100%)`
    }

    if(state === "normal"){
        return  <div className={styles.section} style={menu_style}>{this.props.label}</div>
    } else {
        return  <div className={styles.selected}>{this.props.label}</div>

    }
  }
  hamburgerClick(){

    let new_state = 'hidden';
    if(this.state.hamburger_menu_visibility == 'hidden'){
        new_state = 'visible';
    } 




    this.setState({
      hamburger_menu_visibility: new_state
    })

    

  }
  render() {
    let black = "#000000";

    return (


     

        
        <Link to={this.props.link}>
        
        {this.getItem(this.props.state)}
          
        </Link>
   


    );
  }
}

export default MenuItem;
