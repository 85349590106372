import React, { Component } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import Preview from './Preview';
import Project from './components/project/_Project';
import Work from './_Home';
import About from './_About';
import NotFound from './NotFound';
import Menu from './components/menu/menu.js';
import {Link, RichText, Date} from 'prismic-reactjs';



class App extends Component {
  render() {
    return (
  <Router>

    <Route render={({location}) => (

      <div style={styles.fill}>

        

       <div style={styles.content}>


    <Switch location={location}>

      <Route exact={true} path="/" render={(routeProps) => <Work {...routeProps} prismicCtx={this.props.prismicCtx} />} />
       <Route exact={true} path="/work" render={(routeProps) => <Work {...routeProps} prismicCtx={this.props.prismicCtx} />} />


       <Route exact={true} path="/about" render={routeProps => <About {...routeProps} prismicCtx={this.props.prismicCtx} />} />} 
      <Route exact={true} path="/project/:uid" render={routeProps => <Project {...routeProps} prismicCtx={this.props.prismicCtx} />} />
      <Route render={()=> <div>Not found</div>} />

    </Switch>
   
</div>

    </div>
          )}
        />
  </Router>
);
}
}
export default App;


const styles = {};


styles.content = {
  ...styles.fill,
  margin:0,
  padding:0



};


styles.fill = {
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,

};
