// In src/Page.js

import React from 'react';
import Menu from './components/menu/menu.js';
import NotFound from './NotFound';
import PrismicReact from 'prismic-reactjs';
import Prismic from 'prismic-javascript';
import styles from './components/about/About.module.scss';
import Content from './components/about-content/Content';
import Paragraph from './components/about-paragraph/Paragraph';
import Footer from './components/footer/footer';
import Reveal from 'react-reveal/Reveal';
import Loader from './components/loader/loader';
// Declare your component
class About extends React.Component {

  state = {
    doc: null,
    notFound: false,
  }

  componentWillMount() {
    this.fetchPage(this.props);
  }

  componentWillReceiveProps(props) {
    this.fetchPage(props);
  }

  componentDidUpdate() {
   // this.props.prismicCtx.toolbar();
  }

  fetchPage(props) {
    if (props.prismicCtx) {
      // We are using the function to get a document by its uid
      return props.prismicCtx.api.query(

        Prismic.Predicates.any('document.type', ['about'])


).then((doc) =>


        {
          // We put the retrieved content in the state as a doc variable
          this.setState({ doc });
        }
      );
    }
    return null;
  }
  process_content(content){

      let content_elements = [];
      let html = [];
      
      for(var i=0;i<content.length;i++){
        if(content[i].slice_type=="section"){
          content_elements.push(<Content content={content[i]}/>)
        } 

        if(content[i].slice_type=="paragraph"){
          content_elements.push(<Paragraph content={content[i]} id={i}/>)
        } 
      }

      return  content_elements;
  }
  render() {
    // We will fill in this section in Step 3...
    if (this.state.doc) {

      let results = this.state.doc.results[0].data;
      let content_sections = this.state.doc.results[0].data.body;


      console.log(results)
  
      let intro = PrismicReact.RichText.render(results.intro, this.props.prismicCtx.linkResolver);

      
       return (

           <div>
               <Menu section={"about"}/>
           
                <div className={styles.about}>

                <Reveal effect="fadeInUp" duration={2000}>
                  <div className={styles.intro}>
                       <div className={styles.underline}>
                       {intro}
                       </div>
             
                    </div>
                    </Reveal>
        
        
        
                <div  className={styles.content}>
                    {this.process_content(content_sections)}
                </div>
            </div>
            <Footer/>
            </div> 

       );
     } else if (this.state.notFound) {
       return <NotFound />;
     }
     return (
      
       <Loader/>
     
     );
  }
}

export default About;
