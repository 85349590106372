
import React, { Component } from "react";
import styles from './Description.module.scss';
import {Link, RichText, Date} from 'prismic-reactjs';
import Reveal from 'react-reveal/Reveal';
class TwoImages extends Component {
  
 render() {
   
    

    const linkResolver = function(doc){
        
      }

  return (
    <Reveal effect="fadeInUp" duration={2000}>
    <div className={styles.description}>
    <div className={styles.copy}>
        {RichText.render(this.props.content.description, linkResolver)}
        </div>
   </div>
   </Reveal>
  )
 }
}

export default TwoImages ;
