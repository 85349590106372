import React, { Component } from "react";
import styles from './Content.module.scss';
import {Link, RichText, Date} from 'prismic-reactjs';
import Reveal from 'react-reveal/Reveal';
import Fade from 'react-reveal/Fade';
class Content extends Component {
   
 render() {
   
 

    let section_title = this.props.content.primary.section_title[0].text;
    let column_1 = this.props.content.primary.column_1;
    let column_2 = this.props.content.primary.column_2;

   

  return (
    <Reveal effect="fadeInUp" duration={2000}>
    <div>
      
      
      <div className={styles.about_line}></div>
    
    <div className={styles.about_content}>
      
    
            <div className={styles.title}>
                <span className={styles.underline}>
                  {section_title}
                </span>
            </div>
  
            <div className={styles.column}>
                <Reveal effect="fadeInUp" duration={1500}>
                    {RichText.render(column_1)}
                </Reveal>
            </div>
            <div className={styles.column}>
                <Reveal effect="fadeInUp" duration={2500}>
                {RichText.render(column_2)}
            </Reveal>
          </div>
   </div>
   </div>
   </Reveal>
  )
 }
}

export default Content;