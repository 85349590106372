import React, { Component } from "react";
import styles from './FiveColumnsMobile.module.scss';
import Reveal from 'react-reveal/Reveal';
import MobileColumn from './MobileColumn';
import { Parallax, Background } from 'react-parallax';


class ThreeMobileImages extends Component {

  getImages(items,  offsets){
    let distribution = [];
    let column = 1;
    let column_items = [];

     // figure out how to distribute images between the columns
    for(var i=0;i<items.length;i++){

      
      if(column == items[i].column){
        column_items.push(items[i]);
      } else {
       
        distribution[column-1] = column_items;
        column++;
        column_items = [];
        column_items.push(items[i]);
      }
      distribution[column-1] = column_items;
      

      
     
      
    }


    let images = [];
    let delays = [1500,2500,2000]
    for(var i=0;i< distribution.length;i++){
    

      images.push(<div className={styles.column} style={{"padding-top":offsets[i]/10+'rem'}}><MobileColumn images={distribution[i]}/></div>);
   
    }
    return images;
  }
 render() {


    let img_01 = "";
    let img_02 = "";
    let img_03 = "";
    let background_color = this.props.content.primary.background_color;

    let offsets = [];
  
    

    
  




    offsets[0] = this.props.content.primary.column_1_offset_value;
    offsets[1] = this.props.content.primary.column_2_offset_value;
    offsets[2] =  this.props.content.primary.column_3_offset_value;
    offsets[3] =  this.props.content.primary.column_4_offset_value;
    offsets[4] =  this.props.content.primary.column_5_offset_value;
    let background_image = this.props.content.primary.background_image.url;

    let parent_style ={
      "height": 0,
      "padding-bottom": "62.5%",
      "position": "relative"
    }

    let tile_style = {
      "position":"absolute",
      "background-color":background_color,
      "background-image":"url("+ background_image +")",
      "height":"100%",
      "width":"100%",
     
      "background-size":"100% auto",
      "background-repeat":"no-repeat"
 
    }


  return (

    <div>
        <Reveal effect="fadeInUp" duration={2000}>
        <div  style={parent_style}>
            <div style={tile_style}></div>
        </div>
     </Reveal>


         <div className={styles.imageContainer}>
        


          {this.getImages(this.props.content.items,  offsets)}
       
        </div>

        </div>
    


  )
 }
}

export default ThreeMobileImages ;
