import React, { Component } from "react";
import styles from './TwoImages.module.scss';
import Reveal from 'react-reveal/Reveal';
class TwoImages extends Component {
 render() {
   
     let img_01 = this.props.content.image_01.url;
     let img_02 = this.props.content.image_02.url;
  

     

  return (
     
    <div className={styles.image_container}>
        <Reveal effect="fadeInUp" duration={1500}>
            <div className={styles.image}> <img  src={img_01} /></div>

        </Reveal>
        <Reveal effect="fadeInUp" duration={2000}>
        <div className={styles.image}>  <img src={img_02} /></div>
        </Reveal>
   </div>
  
  )
 }
}

export default TwoImages ;
