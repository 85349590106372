import React, { Component } from "react";
import styles from './SixImages.module.scss';
import Reveal from 'react-reveal/Reveal';
import classNames from 'classnames/bind';
class TiledImages extends Component {
 
    getImages(column, content){
      let images = [];
       
       
        for(let i=0;i<content.length;i++){
          console.log(content[i])
          let image_style = styles.image;

          if(content[i].image_width == 50){
            image_style = styles.image_half;
          }
          if(content[i].column == column){
        images.push(

       
          <Reveal effect="fadeInUp" duration={500*i}>
                <div className={image_style}>

            <img src={content[i].image.url}/>
           
            
            </div>
            </Reveal>
            
         );
        }
      }
   
        return images;
    }
    render() {
   
      
      let cx = classNames.bind(styles);
      let component_margins = styles.component_margins;
     let column_style_1 = styles.column_1_3;
     let column_style_2 = styles.column_2_3;
      let margin_style = styles.margins_both

      

      if(this.props.column_width.component_margins == "none"){
        margin_style = styles.margins_none;
      }

      if(this.props.column_width.component_margins == "bottom"){
        margin_style = styles.margins_bottom;
      }

      if(this.props.column_width.component_margins == "top"){
        margin_style = styles.margins_top;
      }

     if(this.props.column_width.column_1_width == "large"){
      column_style_1 = styles.column_2_3; 
      column_style_2 = styles.column_1_3;
     }
  return (
     
    <div className={cx(styles.image_container,margin_style)}>
      <div className={column_style_1}>{this.getImages(1, this.props.content)}</div>
      <div className={column_style_2}>{this.getImages(2, this.props.content)}</div>
       
       
   </div>
  
  )
 }
}

export default TiledImages;
