import React from 'react';
import ReactDOM from 'react-dom';
import PrismicApp from './PrismicApp';
import './stylesheets/reset.css';
import './stylesheets/animate.css';
import './stylesheets/basic.css';
import { ParallaxProvider } from 'react-scroll-parallax';
ReactDOM.render(
  <ParallaxProvider>
  <PrismicApp />
  </ParallaxProvider>
  ,
  document.getElementById('root'),
);
