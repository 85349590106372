import React, { Component } from 'react';
import styles from './Menu.module.scss';
import { BrowserRouter as Router,Link} from 'react-router-dom'
import cx from 'classnames';


class MenuItemLarge extends Component {
  constructor(props){
    super(props);
    this.state = {
      hamburger_menu_visibility : 'hidden'



    }




  }


 

  getItem(state){

    if(state === "normal"){
        return  <div className={cx(styles.menu_item_large,this.props.animation_speed)}>{this.props.label}</div>
    } else {
        return  <div className={cx(styles.menu_item_large_selected,this.props.animation_speed)}>{this.props.label}</div>

    }
  }
  
  render() {
    let black = "#000000";

    return (


     

        
        <Link to={this.props.link}>
        
        {this.getItem(this.props.state)}
          
        </Link>
   


    );
  }
}

export default MenuItemLarge;
