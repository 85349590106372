import React, { Component } from "react";
import styles from './ThreeImages.module.scss';
import Reveal from 'react-reveal/Reveal';
import classNames from 'classnames/bind';


class TiledImages extends Component {
 
  getImages(column, content, borders){
    let images = [];

    console.log("[[[[]]]]");
    console.log(content);
    
     
      for(let i=0;i<content.length;i++){
        console.log(content[i])
        let image_style = styles.image;

        let tile_style = {
          "background-image":"url("+ content[i].image.url +")",
          "height":"100%",
          "width":"100%",
          "background-size":"100% auto",
          "position":"absolute"
          
    
        };
        let border_height = {
          height:"0px"
      };

      if(content[i].image_width == 50){
        image_style = styles.image_half_noborder;
      }


        if(borders === "yes"){
        border_height= {
            height:"3px"
        };

        if(content[i].image_width == 50){
          image_style = styles.image_half;
        }

      }
       
      



      
        if(content[i].column == column){


      images.push(
          
     
        <Reveal effect="fadeInUp" duration={500*i}>
              <div className={image_style}>
                  <div className={styles.outside_image}>
                        <div className={styles.border} style={border_height}></div>
                        
                        
                        <div className={styles.inside_image} style={tile_style}></div>  
                       
                 
                  </div>
          
          </div>
          </Reveal>
          
       );
      }
    }
 
      return images;
  }
    render() {
   
      
      let cx = classNames.bind(styles);
      let component_margins = styles.component_margins;
      let column_style_1 = styles.column_small;
      let column_style_2 = styles.column_large;
      let column_1_border = styles.border_right;
      let column_2_border = "";
      let borders = this.props.formatting.borders;
      let margin_style = styles.margins_both

      console.log("formatting borders")
      console.log(borders);
      

      if(this.props.formatting.component_margins == "none"){
        margin_style = styles.margins_none;
      }

      if(this.props.formatting.component_margins == "bottom"){
        margin_style = styles.margins_bottom;
      }

      if(this.props.formatting.component_margins == "top"){
        margin_style = styles.margins_top;
      }

     if(this.props.formatting.column_1_width == "large"){
      column_style_1 = styles.column_large;
      column_style_2 = styles.column_small;
     }


    
  return (
     
    <div className={cx(styles.image_container, margin_style)}>
      <div className={cx(column_style_1,column_1_border)}>{this.getImages(1, this.props.content, borders)}</div>
      <div className={cx(column_style_2,column_2_border)}>{this.getImages(2, this.props.content, borders)}</div>

       
       
   </div>
  
  )
 }
}

export default TiledImages;
