import React, { Component } from "react";
import styles from './Paragraph.module.scss';
import {Link, RichText, Date} from 'prismic-reactjs';
import Reveal from 'react-reveal/Reveal';
import Fade from 'react-reveal/Fade';
class Content extends Component {
   
 render() {
   
 
    

 
    let copy = this.props.content.primary.copy;


   

  return (
    <Reveal effect="fadeInUp" duration={1500}>
    <div>
      
      
      
    
    <div className={styles.paragraph_content}>
      
    
           
  
            <div className={styles.paragraph}>
            
                    {RichText.render(copy)}
             
            </div>
           
   </div>
   </div>
   </Reveal>
  )
 }
}

export default Content;