import React, { Component } from 'react';
import styles from './project-thumbnail.module.scss';

import {Link} from 'react-router-dom';

class Loader extends Component {
    constructor(props){
        super(props);

        this.state = {
            underline:{
                
            }
                
           
        }
    }
    
    onMouseEnter(){
        
       
        this.setState(

            {
               
                
               
                scale:{
                    transform: "scale(1.1)"

                },
                underline:{
                    "background-size": "100% 1px"
                }
                
            
            }
        
        
                    
        );

       
        
    }

    onMouseLeave(){
        
       
        this.setState(

            {
               
                
               
                scale:{
                    transform: "scale(1)"

                },
                underline:{
                    "background-size": "0% 1px"
                }
                
            
            }
        
        
                    
        );

       
        
    }
    get_thumbnail(type){
        let thumbnail;

        if(type=="video"){
        thumbnail = <div  className={styles.video}>
               
                
        <video style={this.state.scale} playsinline autoPlay muted loop >
                <source src={this.props.video_url} type="video/mp4"></source>
                </video>
        
        </div>

        }

        if(type=="static"){
            thumbnail = <div className={styles.image} ><img style={this.state.scale} src={this.props.image_url} /></div>
        }


        return thumbnail;

    }
    render() {

       

      return (
        <div className={styles.tile}>
           <Link to={`/project/${this.props.uid}`} onClick={this.forceUpdate}>

           
          <div className={styles.thumbnail} onMouseEnter={() => this.onMouseEnter()} onMouseLeave={() => this.onMouseLeave()}>

                {this.get_thumbnail(this.props.project_type)}

                


                
           
              
                 
                        <div className={styles.project_name} ><span className={styles.underline} style={this.state.underline}>{this.props.project_name}</span></div>
            
              
           

           
               
           
        </div>
        </Link>
        </div>
     
      );
    }
  }

  export default Loader;