import React, { Component } from 'react';
import { BrowserRouter as Router,Link} from 'react-router-dom'
import styles from './Menu.module.scss';
import Logo from '../../imgs/ui/km.svg';
import MenuItem from './menu-item';
import MenuItemLarge from './menu-item-large';



class Menu extends Component {


  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll.bind(this));
    window.addEventListener('resize',(e) => this.handleResize() )
}

componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll.bind(this));
    window.removeEventListener('resize',(e) => this.handleResize() )
}

  
handleResize(event){
  

  if(this.state.hamburger_state === 'menu_hidden'){

  } else {


    this.hide_menu();
  }
}

hide_menu(){
  this.setState({
    menu_state: {

      "background-color":"transparent",
 
    
    },
    menu_hamburger:{
      opacity:0,
      display: "none"

    },
    hamburger_line_top:{
      "background-color":this.state.hamburger_line_color

    },
    hamburger_line_bottom:{
      "background-color":this.state.hamburger_line_color


    },
    logo_color:
      this.props.color
    ,
    hamburger_state:
      "menu_hidden",
  
    
  });


}

  handleScroll(event){

    
      console.log(window.scrollY)
  
   
    let now_scroll = window.scrollY;

    console.log(this.state.last_scroll-now_scroll)

    console.log("now scroll "  + now_scroll)
    let direction = this.state.last_scroll-now_scroll;
    if(direction <0 && this.state.hamburger_state === 'menu_hidden'){
      //console.log("hide menu")
      
      this.setState({
        menu_state:{
          "margin-top":"-10rem",
          "background-color":"transparent"
         

        },
        last_scroll:now_scroll
     
      
      });
      
      

      
  
  
  
      
    } 
    
    // show menu on a white background
    if(direction  > 30 && this.state.hamburger_state === 'menu_hidden' && now_scroll !=0){
      



      this.setState({
        menu_state:{
          "margin-top":"0rem",
          "background-color":"white"

        },
        last_scroll:now_scroll,
        hamburger_line_top:{
          "background-color":"black"
    
        },
        hamburger_line_bottom:{
          "background-color":"black"
    
    
        },
        logo_color:"black"
     
      
      });

      

    }
    // return to default
    if(now_scroll === 0 && this.state.hamburger_state === 'menu_hidden'){
      this.setState({
        menu_state:{
          "margin-top":"0rem",
          "background-color":"transparent"

        },
        hamburger_line_top:{
          "background-color":this.state.hamburger_line_color
    
        },
        hamburger_line_bottom:{
          "background-color":this.state.hamburger_line_color
    
    
        },
        logo_color: this.state.default_logo_color
       
     
      
      });
    }
    
    
     


      
    

  }

  constructor(props){
    super(props);
    this.state = {
      
      hamburger_state:
        "menu_hidden",
        menu_hamburger:{
          opacity:0,
          display:"none"
        },
        
        logo_color:this.props.color,
        default_logo_color: this.props.color,
        hamburger_line_color: this.props.color,
        large_menu_items:{
          "border":"10px solid red"
        },
        last_scroll:0,

        hamburger_line_top:{
          "background-color":this.props.color
    
        },
        hamburger_line_bottom:{
          "background-color":this.props.color
    
    
        }



    }




  }

  


  hamburgerClick(){





    if(this.state.hamburger_state === 'menu_hidden'){
    this.setState({
      menu_state: {
     
        "background-color":"rgba(0, 0, 0, .8)",
        "height":window.innerHeight+'px',
        

      
      },

      
      hamburger_line_top:{
        "background-color":"white",
        "transform-origin": "50% 50%",
        transform: "translate(0,4px) rotate(45deg)"
      },
      hamburger_line_bottom:{
        "background-color":"white",
        "transform-origin": "50% 50%",
        transform: "translate(0,-4px) rotate(-45deg)"
      },
      menu_hamburger:{
        opacity:1,
        'display': 'flex'
      },
      logo_color:
      "white"
      ,
      hamburger_state:
        "menu_shown"
    })
  } else {
    this.hide_menu()


  }

  

  }
  render() {
    let black = "#000000";

   

    let work_state = "normal";
    let about_state = "normal";

    if(this.props.section == "about"){
     about_state = "selected";
    }

    if(this.props.section == "work"){
      work_state = "selected";
     }

    return (


      <div className={styles.menu} style={this.state.menu_state}>

        <div className={styles.desktop_menus}>
            <div className={styles.logo} onClick={(e) => this.hide_menu(e)}>
                <Link to={"../"}>
             <svg>
                  <polygon fill={this.state.logo_color} points="18 0 0 0 0 18 18 18 9 9"/>
	                <polygon fill={this.state.logo_color} points="27 9 18 0 18 18 36 18 36 0"/>
            </svg>
            </Link>
            </div>
     

       

            <div className={styles.menu_hamburger} onClick={(e) => this.hamburgerClick(e)}>
              <div className={styles.hamburger_line} style={this.state.hamburger_line_top}></div>
              <div className={styles.hamburger_line} style={this.state.hamburger_line_bottom}></div>
            </div>
        
        
           <div className={styles.menu_block}>
             
              <MenuItem link={"../"} state={work_state} label={"Work"} color={this.state.logo_color}/>
              <MenuItem link={"../about/"} state={about_state} label={"About"} color={this.state.logo_color}/>
              
       
          </div>
    
      

        </div>

        <div className={styles.menu_hamburger_block} style={this.state.menu_hamburger} onClick={(e) => this.hamburgerClick(e)}>
                
                <MenuItemLarge animation_speed={styles.first_item} link={"../"} state={work_state} label={"Work"} color={this.props.color}/>
                <MenuItemLarge animation_speed={styles.second_item} link={"../about/"} state={about_state} label={"About"} color={this.props.color}/>
               
              

          </div>

  
            
           
      
       
       
       
        

      

      </div>


    );
  }
}

export default Menu;
