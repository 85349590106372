import React, { Component } from 'react';
import styles from './video.module.scss';
import classNames from 'classnames/bind';


class Video extends Component {
    constructor(props){
        super(props);

        this.state = {
            
           
        }
    }
    onMouseEnter(){
        
        var w = window.innerWidth;
   

        console.log(w )
       let margin = "2rem"

       if(w>768){
        this.setState(

            {
                project_info_visiblity: {
                    opacity:1
                },
                
                overlay_margins:{
                    margin:margin },
                image_scale:{
                    transform: "scale(1.1)"

                }
            
            }
        
        
                    
        );

       } 
        
    }
  
    onMouseLeave(){
   

      var w = window.innerWidth;
      if(w>768){

      this.setState(

        {project_info_visiblity: {
            opacity:0
           

        },

        overlay_margins:{
            margin:"0rem" },

            image_scale:{
                transform: "scale(1)"

            }

        }
        
    );

    }
  }


   
    render() {

        console.log("VVVVIDEO --- ");
        console.log(this.props.content.video.url);
        console.log("margins");
        console.log(this.props.content.margins);
        let margins = "yes";
        let margin_style = styles.margins_both;
        let cx = classNames.bind(styles);


        if(this.props.content.margins == "no"){
            margin_style = styles.margins_none;
        }
      return (
       
          <div>
             
           
                <div className={cx(styles.video,margin_style )}>
           
                
                <video style={this.state.image_scale} playsinline="true" autoPlay="true" muted="true" loop >
            <source src={this.props.content.video.url} type="video/mp4"></source></video>
                
                </div>
           
              
            
        </div>
      );
    }
  }

  export default Video;