import React, { Component } from "react";
import styles from './TiledImages.module.scss';
import Reveal from 'react-reveal/Reveal';
class TiledImages extends Component {
 
    getImages(content){
        let images = [];
       
       
        for(let i=0;i<content.length;i++){
      
        images.push(<Reveal effect="fadeInUp" duration={500*i}>
        <div className={styles.image} style={{ "flex-basis": content[i].width + "%"}}> <img  src={content[i].image.url} /></div> </Reveal> );
        }
   
        return images;
    }
    render() {
   
    
  

     

  return (
     
    <div className={styles.image_container}>
        {this.getImages(this.props.content)}
       
   </div>
  
  )
 }
}

export default TiledImages;
