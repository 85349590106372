import React, { Component } from "react";
import styles from './footer.module.scss'
import Reveal from 'react-reveal/Reveal';
class Footer extends Component {
  
 render() {
   


  return (
    <Reveal effect="fadeInUp" duration={2000}>
    <div className={styles.footer}>

        <p><a href="mailto:hi@kirillmazin.com">hi@kirillmazin.com</a></p>
        <p><a href="https://www.linkedin.com/in/kirillmazin/">LinkedIn</a></p>
        <p><a href="https://www.instagram.com/kirillmazin">Instagram</a></p>
     
    </div>
    </Reveal>

  )
 }
}

export default Footer;
