

import React from 'react';
import NotFound from '../.././NotFound';
import PrismicReact from 'prismic-reactjs';
import styles from './home-tile.module.scss';
import {Link} from 'react-router-dom';
import Reveal from 'react-reveal/Reveal';
import Loader from '../loader/loader';
import Thumbnail from '../home-thumbnail-static/home-thumbnail';

// Declare your component
export default class homeTile extends React.Component {

  state = {
    doc: null,
    notFound: false,
  }

  componentWillMount() {
    this.fetchPage(this.props);
  }

  componentWillReceiveProps(props) {
    this.fetchPage(props);
  }

  componentDidUpdate() {
    //this.props.prismicCtx.toolbar();
  }

  fetchPage(props) {
    if (props.prismicCtx) {
      // We are using the function to get a document by its uid
      return props.prismicCtx.api.getByUID('case-study', props.uid, {}, (err, doc) => {
        if (doc) {
          // We put the retrieved content in the state as a doc variable
          this.setState({ doc });
        } else {
          // We changed the state to display error not found if no matched doc
          this.setState({ notFound: !doc });
        }
      });
    }
    return null;
  }
 

   getThumbnail(thumbnail_type, video_url, project_name, project_tagline){
       let thumbnail = '';

      
        const img_height= this.state.doc.data.thumbnail_image.dimensions.height;
        const img_width = this.state.doc.data.thumbnail_image.dimensions.width;

        const a_r = img_width / img_height;

       
        thumbnail = <Thumbnail type={thumbnail_type} video_url={video_url} src={this.state.doc.data.thumbnail_image.url} project_name={project_name} project_tagline={project_tagline}/>

      
       


       return thumbnail;
   }
  render() {
    
    
    if (this.state.doc) {

        let uid = this.props.uid;
        let delay = (this.props.id+1)*1200;
        let thumbnail_type = this.state.doc.data.thumbnail_type;
        let video_url = this.state.doc.data.thumbnail_video.url;
        let project_name = PrismicReact.RichText.asText(this.state.doc.data.project_name);
        let project_tagline = PrismicReact.RichText.asText(this.state.doc.data.tagline);


       
       return (

        <Reveal effect="fadeInUp" duration={delay}>
       <div className={styles.tile}>
               <Link to={`/project/${uid}`}>
            {this.getThumbnail(thumbnail_type,video_url, project_name,project_tagline)}
                </Link>
                </div>
         </Reveal>

       );
     } else if (this.state.notFound) {
       return <NotFound />;
     }
     return <Loader/>;
  }
}
