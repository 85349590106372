import React, { Component } from "react";
import styles from './ThreeColumnsDesktop.module.scss';
import Reveal from 'react-reveal/Reveal';
import DesktopColumn from './DesktopColumn';
import cx from 'classnames';
import { Parallax, Background } from 'react-parallax';


class ThreeColumnsDesktop extends Component {

  getImages(items,offsets){
    let distribution = [];
    let column = 1;
    let column_items = [];

     // figure out how to distribute images between the columns
    for(var i=0;i<items.length;i++){

      
      if(column == items[i].column){
        column_items.push(items[i]);
      } else {
       
        distribution[column-1] = column_items;
        column++;
        column_items = [];
        column_items.push(items[i]);
      }
      distribution[column-1] = column_items;
      

      
     
      
    }


    let images = [];
    let delays = [1500,2500,2000]
    for(var i=0;i< distribution.length;i++){


      images.push(<div className={styles.column} style={{"padding-top":offsets[i]/10+'rem'}}><DesktopColumn images={distribution[i]}/></div>);
   
    }
    return images;
  }
 render() {


    let img_01 = "";
    let img_02 = "";
    let img_03 = "";
    let background_color = this.props.content.primary.background_color;
    let offsets = [];

    offsets[0] = this.props.content.primary.column_1_offset_value;
    offsets[1] = this.props.content.primary.column_2_offset_value;
    offsets[2] =  this.props.content.primary.column_3_offset_value;

    console.log(this.props.content.primary);
    let background_image = this.props.content.primary.background_image.url;
    console.log("----");
    console.log(background_image);
    let display_background = "block";
    if( background_image == undefined){
      display_background = "none"
    
    }


    let parent_style ={
      "height": 0,
      "padding-bottom": "62.5%",
      "position": "relative",
      "overflow":"hidden",
      "display":display_background

    }

    let tile_style = {
      "position":"absolute",
      "background-color":background_color,
      "background-image":"url("+ background_image +")",
      "height":"100%",
      "width":"100%",
     
      "background-size":"100% auto",
      "background-repeat":"no-repeat"
 
    }


 
    let margin_style = styles.margin;
    if(background_image === undefined){
      margin_style = styles.no_margin;
    }


    


  return (


   
    <div>
       <Reveal effect="fadeInUp" duration={2000}>
       <div  style={parent_style}>
             <div style={tile_style}></div>
        </div>
        </Reveal>
         <div className={cx(styles.imageContainer,  margin_style)}>
         
          {this.getImages(this.props.content.items,  offsets)}
       
        </div>

        </div>


    


  )
 }
}

export default ThreeColumnsDesktop ;
