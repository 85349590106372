// In src/Page.js

import React from 'react';


import Prismic from 'prismic-javascript';

import styles from './NextProject.module.scss';
import cx from 'classnames';
import Reveal from 'react-reveal/Reveal';
import Thumbnail from '../project-thumbnail-static/project-thumbnail';
import PrismicReact from 'prismic-reactjs';
// Declare your component
class NextProject extends React.Component {

  state = {
    doc: null,
    notFound: false,
  }

  componentWillMount() {
    this.fetchPage(this.props);
  }

  componentWillReceiveProps(props) {
    this.fetchPage(props);
  }

  componentDidUpdate() {
    //this.props.prismicCtx.toolbar();
  }

  fetchPage(props) {
    if (props.prismicCtx) {
      // We are using the function to get a document by its uid
      return props.prismicCtx.api.query(

        Prismic.Predicates.any('document.type', ['home','case-study'])


).then((doc) =>


        {
          // We put the retrieved content in the state as a doc variable
          this.setState({ doc });
        }
      );
    }
    return null;
  }
  getTiles(content){
    let pTiles = [];


    let project_type;
    let video_url;
    let project_name;
    let image_url;
    let uid;
    for(var i=0;i<content.length;i++){
    project_type = content[i].data.thumbnail_type;
    video_url = content[i].data.thumbnail_video.url;
    project_name = PrismicReact.RichText.asText(content[i].data.project_name);
    image_url = content[i].data.thumbnail_image.url;
    uid = content[i].uid;

    console.log(content[i])
    
          pTiles.push(<Thumbnail uid={uid} project_type={project_type} video_url={video_url} image_url={image_url} project_name={project_name} />)
     
    }

        

    return pTiles;
  }
  render() {
    // We will fill in this section in Step 3...
   
    if (this.state.doc) {

     
 

      // Process the results
      let results = this.state.doc.results;
      let home_tiles;
      let tiles = [];
      let home_uids = [];
      let case_studies = [];
      let home_case_studies = [];
 

            
      for(var i=0;i<this.state.doc.results.length;i++){
     
        let type = this.state.doc.results[i].type;
        if(type == "home"){
          home_tiles = this.state.doc.results[i].data.body;
        }

        if(type == "case-study"){
        case_studies.push(this.state.doc.results[i]);
        }

      }
      
      for(var i=0; i<home_tiles.length; i++){
            

        tiles.push(home_tiles[i]);
        

        for(var l=0;l<home_tiles[i].items.length;l++){
     
          home_uids.push(home_tiles[i].items[l].project.uid)
        }

      }

     
  

      let case_studies_show = [];
 
      let idPreviousProject = 0;
      let idNextProject = 0;

      let first_project = 0;
      let second_project = 0;
      let third_project = 0;
      let fourth_project = 0;
      for(let i=0;i<home_uids.length;i++){
       

        for(let l=0;l<case_studies.length;l++){
            if(home_uids[i]=== case_studies[l].uid){
              home_case_studies.push(case_studies[l]);
            }

        

        }
      }

   

      for(let i=0;i<  home_case_studies.length;i++){
       
     
    

        if(this.props.uid ===   home_case_studies[i].uid){
       
         
          first_project = i>= 2 ? i-2 : home_case_studies.length-2; 
          second_project = i>= 1 ? i-1 : home_case_studies.length-1; 
          third_project = i+1 < home_case_studies.length ?   i+1:0;
          fourth_project = i+2 < home_case_studies.length ?i+2:1;

         
      
        case_studies_show.push(  home_case_studies[first_project ]);
          case_studies_show.push(  home_case_studies[second_project ]);
         case_studies_show.push(  home_case_studies[third_project]);
          case_studies_show.push(  home_case_studies[fourth_project]);
        }

        




        

      }
       return (






        <Reveal effect="fadeInUp" duration={2000}>
     
                <div className={styles.project_nav}>
                <h2 className={styles.underline}>Other projects</h2>

                  <div className={styles.case_studies}>
                      {this.getTiles(case_studies_show)}


                      
                  </div>
                     
                </div>

               </Reveal>
                
           


       );
     } else if (this.state.notFound) {
       return "";
     }
     return (

    <div className="page home">

 
</div>

 );
  }
}

export default NextProject;
