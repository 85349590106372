import React, { Component } from "react";

import Reveal from 'react-reveal/Reveal';

import styles from './ProjectIntro.module.scss';
class ProjectIntro extends Component {
 render() {
   
     

  return (
     
    <div className={styles.intro}>
     
        <Reveal effect="fadeInUp">
            <div className={styles.description}>
            <h1 className={styles.project_title}><span className={styles.underline}>{this.props.project_name}</span></h1>
            <div>{this.props.project_description}</div>

            <div className={styles.created_at}>{this.props.project_designed_at}</div>

            </div>
        </Reveal>
   </div>
  
  )
 }
}

export default ProjectIntro ;
