import React, { Component } from 'react';
import styles from './loader.module.scss';


class Loader extends Component {
    render() {
      return (
        <div className={styles.loader_parent}>
        <div className={styles.loader}>

        
        </div>

        </div>
      );
    }
  }

  export default Loader;