import React, { Component } from 'react';
import styles from './LargeBrowser.module.scss';


class LargeBrowser extends Component {
    constructor(props){
        super(props);

        this.state = {
            
           
        }
    }
    onMouseEnter(){
        
        var w = window.innerWidth;
   

        console.log(w )
       let margin = "2rem"

       if(w>768){
        this.setState(

            {
                project_info_visiblity: {
                    opacity:1
                },
                
                overlay_margins:{
                    margin:margin },
                image_scale:{
                    transform: "scale(1.1)"

                }
            
            }
        
        
                    
        );

       } 
        
    }
  
    onMouseLeave(){
   

      var w = window.innerWidth;
      if(w>768){

      this.setState(

        {project_info_visiblity: {
            opacity:0
           

        },

        overlay_margins:{
            margin:"0rem" },

            image_scale:{
                transform: "scale(1)"

            }

        }
        
    );

    }
  }


   
    render() {

  
      return (
       
     
             
           
                <div className={styles.video}>
                        
                                <div className={styles.browser_bar}>
                                <span className={styles.button}></span>
                                <span className={styles.button}></span>
                                <span className={styles.button}></span>
                                </div>
                                <video style={this.state.image_scale} playsinline autoPlay muted loop >
                                    <source src={this.props.content.video.url} type="video/mp4"></source>
                                </video>
                   
                </div>
           
              
     
      );
    }
  }

  export default LargeBrowser;