// In src/Page.js

import React from 'react';
import Menu from './components/menu/menu.js';
import NotFound from './NotFound';
import PrismicReact from 'prismic-reactjs';
import Prismic from 'prismic-javascript';

import HomeRow from './components/home-row/home-row.js';
import Loader from './components/loader/loader';
import styles from './components/home/Home.module.scss';
import Footer from './components/footer/footer';

// Declare your component
class Home extends React.Component {

  state = {
    doc: null,
    notFound: false,
  }
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  componentWillMount() {
    this.fetchPage(this.props);
  }

  componentWillReceiveProps(props) {
    this.fetchPage(props);
  }

  componentDidUpdate() {
    //this.props.prismicCtx.toolbar();
  }

  fetchPage(props) {
    if (props.prismicCtx) {
      // We are using the function to get a document by its uid
      return props.prismicCtx.api.query(

        Prismic.Predicates.any('document.type', ['home','case-study'])


).then((doc) =>


        {
          // We put the retrieved content in the state as a doc variable
          this.setState({ doc });
        }
      );
    }
    return null;
  }
  

  getHomeRows(content){
    let rows = [];


    for(let i=0;i<content.length;i++){
      rows.push(<HomeRow content={content[i]} prismicCtx={this.props.prismicCtx}></HomeRow>)
     
    }

    return rows;

  }
  render() {
    // We will fill in this section in Step 3...
    
    if (this.state.doc) {

    


      // Process the results
      let results = this.state.doc.results;
      let type;
      let caseStudies = [];
      let tiles = [];
      for(let i=0;i<results.length;i++){
        //console.log(results[i])
        type = results[i].type;




        if(type == "home"){
        

      
            let home_data = results[i].data.body;
            for(var l=0; l<home_data.length; l++){
            

              tiles.push(home_data[l]);

            }
        }

        if(type == "case-study"){
            caseStudies.push(results[i]);
          

     
        }


      }
       return (






            <div className={styles.home}>
              <Menu color={"#000000"} section={"work"}/>

                <div className={styles.home_grid}>
                     {this.getHomeRows(tiles)}
                </div>
                <Footer/>
            </div>


       );
     } else if (this.state.notFound) {
       return <NotFound />;
     }
     return (

    <div className="page home">

       <Loader/>
</div>

 );
  }
}

export default Home;
