import React, { Component } from "react";
import {Link} from 'react-router-dom';
import styles from './home-row.module.scss';
import Reveal from 'react-reveal/Reveal';
import HomeTile from '../home-tile/home-tile';

class HomeRow extends Component {
  getProjects(content){
    let projects = [];
    let tile_type = "single"
    if (content.length === 2){ tile_type  = "double"}
    if (content.length === 3){ tile_type  = "triple"}


    console.log("TILE TYPE");
    console.log(tile_type );

    for(let i=0;i<content.length;i++){
    
     if(tile_type != "triple"){
      projects.push(<HomeTile key={i} id={i} tile_type={tile_type} content={content[i]} prismicCtx={this.props.prismicCtx} uid={content[i].project.uid}></HomeTile>)
     } 
      
 

  


  
    }

    if(tile_type == "triple"){

      projects.push(
        <div className={styles.triple}>
          <div className={styles.triple_first}>
            <HomeTile key={0} id={0} tile_type={tile_type} content={content[0]} prismicCtx={this.props.prismicCtx} uid={content[0].project.uid}></HomeTile>
        </div>
        <div className={styles.triple_other}>
          <HomeTile key={1} id={1} tile_type={tile_type} content={content[1]} prismicCtx={this.props.prismicCtx} uid={content[1].project.uid}></HomeTile>
          <HomeTile key={2} id={1} tile_type={tile_type} content={content[2]} prismicCtx={this.props.prismicCtx} uid={content[2].project.uid}></HomeTile>
          </div>
  </div>
      );

    }

    return projects;

  }
 render() {


  let content = this.props.content;
  let projects = []
  
  
    for(let i=0;i<content.items.length;i++){
      
        projects.push(content.items[i]);
   }
   




  return (

    <Reveal effect="fadeInUp" duration={1500}>
    <div className={styles.row}>
    <div className={styles.content}>
    
      {this.getProjects(projects)}

      </div>

    
    </div>
    </Reveal>

  )
 }
}

export default HomeRow;
