import React, { Component } from "react";
import {Link} from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import Reveal from 'react-reveal/Reveal';
import styles from './image.module.scss'
import cx from 'classnames';
class Image extends Component {
 render() {

     let img = this.props.content.image.url;
     const margins = this.props.content.margins;
        let margin_style = styles.margin;
    if(margins === 'no') margin_style = styles.no_margin; 
    
  return (

    <div>
      
   <div className={cx(
            styles.image,
            margin_style
          )}> 
       
       <Reveal effect="fadeInUp" duration={2000}>


       
        <img alt="cover" src={img} />
        
        
        </Reveal>
        </div>
        </div>

  )
 }
}

export default Image ;
