

import React from 'react';
import Menu from '../menu/menu.js';
import NotFound from '../../NotFound';
import PrismicReact from 'prismic-reactjs';
import Image from '../image/image.js';
import TwoImages from '../two-images/TwoImages.js';
import TiledImages from '../tiled-images/TiledImages.js';
import SixImages from '../six-images/SixImages.js';
import ThreeImages from '../three-images/ThreeImages.js';
import ThreeMobileImages from '../three-mobile-images/ThreeMobileImages.js';
import ThreeColumnsDesktop from '../three-columns-desktop/ThreeColumnsDesktop.js';
import FiveColumnsMobile from '../five-columns-mobile/FiveColumnsMobile.js';
import NextProject from '../next-project/NextProject';
import Description from '../description/Description';
import ProjectIntro from '../project-intro/ProjectIntro';
import Loader from '../loader/loader';
import styles from './Project.module.scss';
import Video from '../video/video';
import LargeBrowser from '../large-browser/LargeBrowser';
import DesignedAt from '../designed-at/Designed_at';
import Hero from '../hero/hero';
import Footer from '../footer/footer';

// Declare your component
export default class Page extends React.Component {
  constructor(props) {
    super(props);
   
  }

  state = {
    doc: null,
    notFound: false,
  }
  componentDidMount() {
    

    window.scrollTo(0, 0)
  }
  componentWillMount() {
    this.fetchPage(this.props);
  }

  componentWillReceiveProps(props) {
    this.fetchPage(props);
  }

  componentDidUpdate() {
    //this.props.prismicCtx.toolbar();
 
  }

  fetchPage(props) {
    if (props.prismicCtx) {
      // We are using the function to get a document by its uid
      return props.prismicCtx.api.getByUID('case-study', props.match.params.uid, {}, (err, doc) => {
        if (doc) {
          // We put the retrieved content in the state as a doc variable
          this.setState({ doc });
        } else {
          // We changed the state to display error not found if no matched doc
          this.setState({ notFound: !doc });
        }
      });
    }
    return null;
  }
    getImages(slices){
       
        let all_images = [];


        for(var i=0;i<slices.length;i++){
         
          console.log(slices[i].slice_type );
         

          if(slices[i].slice_type === "designed_at"){
           
          }
            if(slices[i].slice_type === "large_image"){
               
          

               
               all_images.push(<Image key={i} content={slices[i].primary}/>);
               }

               if(slices[i].slice_type === "two_images"){
               

                  all_images.push(<TwoImages key={i} content={slices[i].primary}/>);
               }

               if(slices[i].slice_type === "six_images"){
      

              all_images.push(<SixImages key={i} column_width={slices[i].primary} content={slices[i].items}/>)
             }

               if(slices[i].slice_type === "tiled_images"){
                console.log("WE HAVE TILED IMAGES");
                console.log(slices[i].items);

              all_images.push(<TiledImages key={i} content={slices[i].items}/>)
             }

               if(slices[i].slice_type === "three_images"){
             
             
                console.log(slices[i].primary );

                all_images.push(<ThreeImages key={i} formatting={slices[i].primary} content={slices[i].items}/>);

              }

              if(slices[i].slice_type === "three_mobile_images"){
                
              
                all_images.push(<ThreeMobileImages key={i} content={slices[i].primary}/>);

              }

              if(slices[i].slice_type === "three_rows_desktop"){
                
              
                all_images.push(<ThreeColumnsDesktop key={i} content={slices[i]}/>);

              }

              if(slices[i].slice_type === "five_rows_mobile"){
              
              
                all_images.push(<FiveColumnsMobile key={i} content={slices[i]}/>);

              }

              if(slices[i].slice_type === "large_browser"){
            

                  all_images.push(<LargeBrowser key={i} content={slices[i].primary}/>);
              }


              if(slices[i].slice_type==="description"){
          
                all_images.push(<Description key={i} content={slices[i].primary}/>);
              }

              if(slices[i].slice_type==="video"){
                
                console.log(slices[i].primary);
          
                all_images.push(<Video key={i} content={slices[i].primary}/>);
              }
        }
        return all_images;
    }
  render() {
    // We will fill in this section in Step 3...
      
    let project_components;
    let slice_type;
    let project_name;
    let project_description;
    let project_designed_at;
    let logo_color;
    let hero_type;
    let hero_video;
    if (this.state.doc) {

      project_name = PrismicReact.RichText.asText(this.state.doc.data.project_name);
      project_description = PrismicReact.RichText.render(this.state.doc.data.project_description, this.props.prismicCtx.linkResolver);
      project_designed_at = PrismicReact.RichText.asText(this.state.doc.data.designed_at);
      hero_type = this.state.doc.data.hero_type != undefined ? this.state.doc.data.hero_type : "static";
      hero_video = this.state.doc.data.hero_video.url;

      
      logo_color = this.state.doc.data.logo_color;
      let hero_overlay_opacity = this.state.doc.data.overlay_opacity != undefined? this.state.doc.data.overlay_opacity : 0.6;

      
        
      project_components =  this.getImages(this.state.doc.data.body);

      
        
       return (

         <div >
            <Menu color={logo_color} ref={this.myRef}/>
            <Hero hero_type={hero_type} video_url={hero_video} project_name={project_name}  overlay_opacity={hero_overlay_opacity} img={this.state.doc.data.hero_image.url}/>
        
          
           
           <ProjectIntro project_name={project_name} project_description={project_description}    project_designed_at={project_designed_at}/>
          
                    
             
               <div>   
                  <div className="project-image">{project_components}</div> 
                   

                   
                  </div>


          

                    <NextProject prismicCtx={this.props.prismicCtx} uid={this.props.match.params.uid}/>
       
                   
                    <Footer/>
                   
         </div>
    
       );
     } else if (this.state.notFound) {
       return <NotFound />;
     }
     return <Loader/>;
  }
}
