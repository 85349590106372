import React, { Component } from "react";
import styles from './ThreeMobileImages.module.scss';
import Reveal from 'react-reveal/Reveal';
import { Parallax, Background } from 'react-parallax';


class ThreeMobileImages extends Component {
 render() {
    let img_01 = this.props.content.image_01.url;
    let img_02 = this.props.content.image_02.url;
    let img_03 = this.props.content.image_03.url;
    let background_color = this.props.content.background_color;
    let background_image = this.props.content.background_image.url;


    let tile_style = {
      "background-color":background_color,
      "background-image":"url("+background_image+")"
    }
  return (


   

<Parallax
      
            bgImage={background_image}
     
            strength={400}
            bgImageStyle={{opacity: '1', transform: 'scale(2, 0.5)'}}
       
            
        >
         <div className={styles.imageContainer} >

        <Reveal effect="fadeInUp" duration={1500} >
          <div className={styles.image}> <img alt="cover" src={img_01} /></div>
        </Reveal>
        <Reveal effect="fadeInUp" duration={2500}>
        <div className={styles.image}> <img alt="cover" src={img_02} /></div>
        </Reveal>
        <Reveal effect="fadeInUp" duration={2000}>
        <div className={styles.image}> <img alt="cover" src={img_03} /></div>
        </Reveal>
        </div>

        </Parallax>
    


  )
 }
}

export default ThreeMobileImages ;
