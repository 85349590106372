import React, { Component } from 'react';
import styles from './home-thumbnail.module.scss';


class Loader extends Component {
    constructor(props){
        super(props);

        this.state = {
            
           
        }
    }

    get_image(type){
        let thumbnail;
        if(type != 'video'){
            thumbnail = <div className={styles.image}><img style={this.state.image_scale} src={this.props.src} /></div>
           
        } else {
            thumbnail =  <div className={styles.video}>
                
                
            <video muted autoPlay playsinline="true" loop="true" style={this.state.image_scale}>
                <source src={this.props.video_url} type="video/mp4"></source>
            </video>
            
            </div>
        }
        return thumbnail;
    }
    onMouseEnter(){
        
        var w = window.innerWidth;
   

        console.log(w )
       let margin = "2rem"

       if(w>768){
        this.setState(

            {
                project_info_visiblity: {
                    opacity:1
                },
                
                overlay_margins:{
                    margin:margin },
                image_scale:{
                    transform: "scale(1.1)"

                },
                
                underline:{backgroundSize: "100% 2px"}
            
            }
        
        
                    
        );

       } 
        
    }
  
    onMouseLeave(){
   

      var w = window.innerWidth;
      if(w>768){

      this.setState(

        {project_info_visiblity: {
            opacity:0
           

        },

        overlay_margins:{
            margin:"0rem" },

            image_scale:{
                transform: "scale(1)"

            },
            underline:{backgroundSize: "1% 2px"}

        }
        
    );

    } 
  }
   
    render() {
      return (
         
          <div className={styles.thumbnail} onMouseEnter={() => this.onMouseEnter()} onMouseLeave={() => this.onMouseLeave()}>
               
               {this.get_image(this.props.type)}
                
                <div className={styles.project_info_mobile}>
                    <div className={styles.copy}>
                        <div className={styles.project_name}>
                            <span className={styles.underline} >{this.props.project_name}</span>
                        </div>
                        <div className={styles.project_tagline}>{this.props.project_tagline}</div>
                    </div>

                </div>

             <div className={styles.project_info} style={this.state.project_info_visiblity}>
                <div className={styles.overlay} style={this.state.overlay_margins}>
                  
                     <div className={styles.copy}>
                        <div className={styles.project_name}><span style={this.state.underline} className={styles.underline}>{this.props.project_name}</span></div>
                        <div className={styles.project_tagline}>{this.props.project_tagline}</div>
                    </div>
                    

                     
                </div>
               
            </div>
        </div>
     
      );
    }
  }

  export default Loader;